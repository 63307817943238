import React from "react";
import { Link } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";
import { BiEnvelope } from "react-icons/bi";
import DashboardLogout from "../DashboardLogout/DashboardLogout";
import { useLanguage } from "../../utils/LanguageContext";
import "../Dashboard/Dashboard.css";

function OwnerSideBar({
  isSidebarOpen,
  handleCloseSidebar,
  activeTab,
  setActiveTab,
}) {
  const { translate } = useLanguage();
  
  return (
    <>
    
      <div className={`sidebar  ${isSidebarOpen ? "open" : ""}`}>
        <ul>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div className="owner-dashboardLogo-img mb-0 mb-lg-4">
              <Link to="/dashboard">
                <img alt="" src="/assets/icons/dashboardLogo.png" />
              </Link>
            </div>
            <IoCloseOutline
              className="close-btn-ownerDashboard d-block d-lg-none"
              onClick={handleCloseSidebar}
            />
          </div>

          <div className="logout-button-owner-login d-none d-lg-block mt-3">
            <DashboardLogout />
          </div>
          <h1 className="dashboard-subTitle mb-2">{translate("DASHBOARD")}</h1>
          <hr />
          <div className="chat-sidebar mb-3">
            <h1 className="dashboard-subTitle mb-2"
           onClick={() => {
            setActiveTab("Chats");
            handleCloseSidebar();
          }}
            >{translate("Chat")}</h1>

            <BiEnvelope style={{ fontSize: "20px", color: "#6c9b2e" }} />
          </div>
          <hr />
          <li
            className={activeTab === "dashboard-home" ? "active" : ""}
            onClick={() => {
              setActiveTab("dashboard-home");
              handleCloseSidebar();
            }}
          >
            {translate("Dashboard")}
          </li>
          <li
            className={activeTab === "all-property-inquirery" ? "active" : ""}
            onClick={() => {
              setActiveTab("all-property-inquirery");
              handleCloseSidebar();
            }}
          >
            {translate("All Property Inquiry")}
          </li>
          <li
            className={activeTab === "AllBoatInquiry" ? "active" : ""}
            onClick={() => {
              setActiveTab("AllBoatInquiry");
              handleCloseSidebar();
            }}
          >
            {translate("All Boat Inquiry")}
          </li>
          <li
            className={activeTab === "Inqury-method" ? "active" : ""}
            onClick={() => {
              setActiveTab("Inqury-method");
              handleCloseSidebar();
            }}
          >
            {translate("Inquiry Methods")}
          </li>
          <h1 className="dashboard-subTitle mt-5 mb-3">
            {translate("ACCOMODTION")}
          </h1>
          <li
            className={activeTab === "all-property" ? "active" : ""}
            onClick={() => {
              setActiveTab("all-property");
              handleCloseSidebar();
            }}
          >
            {translate("All Property")}
          </li>

          <li
            className={activeTab === "roomCategory" ? "active" : ""}
            onClick={() => {
              setActiveTab("roomCategory");
              handleCloseSidebar();
            }}
          >
            {translate("Room Category")}
          </li>
          {/* <li
            className={activeTab === "all-booked-property" ? "active" : ""}
            onClick={() => {
              setActiveTab("all-booked-property");
              handleCloseSidebar();
            }}
          >
            {translate("All Booked Property")}
          </li> */}
          <li
            className={activeTab === "pending-booking-property" ? "active" : ""}
            onClick={() => {
              setActiveTab("pending-booking-property");
              handleCloseSidebar();
            }}
          >
            {translate("Pending Booked Property")}
          </li>
          <li
            className={activeTab === "success-booked-property" ? "active" : ""}
            onClick={() => {
              setActiveTab("success-booked-property");
              handleCloseSidebar();
            }}
          >
            {translate("Success Booked Property")}
          </li>
          <li
            className={activeTab === "cancle-booked-property" ? "active" : ""}
            onClick={() => {
              setActiveTab("cancle-booked-property");
              handleCloseSidebar();
            }}
          >
            {translate("Cancel Booked Property")}
          </li>
          <li
            className={activeTab === "all-Boat" ? "active" : ""}
            onClick={() => {
              setActiveTab("all-Boat");
              handleCloseSidebar();
            }}
          >
            {translate("All Boat")}
          </li>
          {/* <li
            className={activeTab === "all-booked-boat" ? "active" : ""}
            onClick={() => {
              setActiveTab("all-booked-boat");
              handleCloseSidebar();
            }}
          >
            {translate("All Booked Boat")}
          </li> */}
          <li
            className={activeTab === "pending-booked-Boat" ? "active" : ""}
            onClick={() => {
              setActiveTab("pending-booked-Boat");
              handleCloseSidebar();
            }}
          >
            {translate("Pending Booked Boat")}
          </li>
          <li
            className={activeTab === "success-Booked-Boat" ? "active" : ""}
            onClick={() => {
              setActiveTab("success-Booked-Boat");
              handleCloseSidebar();
            }}
          >
            {translate("Success Booked Boat")}
          </li>
          <li
            className={activeTab === "cancle-booked-boat" ? "active" : ""}
            onClick={() => {
              setActiveTab("cancle-booked-boat");
              handleCloseSidebar();
            }}
          >
            {translate("Cancel Booked Boat")}
          </li>
          <h1 className="dashboard-subTitle mb-3 mt-3">
            {translate("WITHDRAW")}
          </h1>

          <li
            className={activeTab === "Withdraw-money" ? "active" : ""}
            onClick={() => {
              setActiveTab("Withdraw-money");
              handleCloseSidebar();
            }}
          >
            {translate("Withdraw Money")}
          </li>
          <li
            className={activeTab === "withdraw-log" ? "active" : ""}
            onClick={() => {
              setActiveTab("withdraw-log");
              handleCloseSidebar();
            }}
          >
            {translate("Withdraw Log")}
          </li>
          <h1 className="dashboard-subTitle mb-3 mt-4">
            {translate("SUPPORT TICKET")}
          </h1>
          <li
            className={activeTab === "open-ticket" ? "active" : ""}
            onClick={() => {
              setActiveTab("open-ticket");
              handleCloseSidebar();
            }}
          >
            {translate("Open Ticket")}
          </li>
          <li
            className={activeTab === "ticket-history" ? "active" : ""}
            onClick={() => {
              setActiveTab("ticket-history");
              handleCloseSidebar();
            }}
          >
            {translate("Ticket History")}
          </li>
          <div className="logout-button-owner-login d-block d-lg-none mt-3">
            <DashboardLogout />
          </div>
        </ul>
      </div>
    </>
  );
}
export default OwnerSideBar;
