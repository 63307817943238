import React, { useState } from "react";
import "./BoatPage.css";
import Navbar from "../../Components/Navbar/Navbar";
import BoatSearchEngine from "../../Components/BoatSearchEngine/BoatSearchEngine";
import { BiCheck } from "react-icons/bi";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { showAmount } from "../../utils/showAmount";
import { BsFillStarFill } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FaMapMarkerAlt } from "react-icons/fa";
import Footer from "../../Components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { addBoat } from "./boatSlice";
import { Box, CircularProgress, Stack } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Wishlist from "../../Components/Wishlist";
import { useLanguage } from "../../utils/LanguageContext";
import BoatCarousel from "./BoatCarousel";
import MainSearchEngine from "../../Components/MainSearchEngine/MainSearchEngine";
function BoatPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, slug } = useParams();
  const location = useLocation();
  // const [boat, setBoat] = useState("");
  const { boat } = useSelector((state) => state.boat);
  const [loading, setLoading] = useState(false);
  const [filter_location, setFilterLocation] = useState("");
  const [filter_boatTypeId, setFilterBoatTypeId] = useState("");

  const [boats, setBoats] = useState("");
  const { translate } = useLanguage();

  function generateSlug(inputString) {
    const slug = inputString.toLowerCase().replace(/[^a-z0-9]+/g, "-");
    return slug.replace(/^-+|-+$/g, "");
  }

  const handleDetails = (item) => {
    if (slug) {
      navigate(`/boat/${item?.id}/${slug}/details`);
    } else {
      const resultSlug = generateSlug(item.name);
      navigate(`/boat/${item?.id}/${resultSlug}/details`);
    }
  };

  const handleChildData = (data) => {
    setBoats(data);
    dispatch(addBoat(data));
  };

  return (
    <Box>
      {loading && (
        <Stack height="100vh" alignItems="center" justifyContent="center">
          <CircularProgress size={100} sx={{ color: "#8bc53e" }} />
        </Stack>
      )}
      {!loading && (
        <>
          <Navbar />

          <div className="main-heading boat-heading">
            <h1>{boat?.page_title}</h1>
          </div>
          <div className="container">
            <div className="mt-4">
              <MainSearchEngine
                boat_types={boats.boat_types}
                locations={boats.locations}
                onDataReceived={handleChildData}
                locationId={filter_location}
                boat_typeId={filter_boatTypeId}
              />
              {/* <BoatSearchEngine
                boat_types={boats.boat_types}
                locations={boats.locations}
                onDataReceived={handleChildData}
                locationId={filter_location}
                boat_typeId={filter_boatTypeId}
              /> */}
            </div>
          </div>
          <div className="container">
            <div className="property-section-heading">
              <div className="property-headings">
                <img className="" src="/assets/icons/aa1.png" alt="Image" />
                <h1>{translate(boat?.boat?.heading)}</h1>

                <img
                  className="img2"
                  src="/assets/icons/aa2.jpeg"
                  alt="Image"
                />
              </div>
            </div>
            <p className="text-center">{translate(boat?.boat?.sub_heading)}</p>
          </div>

          <div>
            <BoatCarousel />
          </div>
          <div className="container">
            <div className="ship-list-wrapper">
              <div className="ship-list-content">
                <div className="ship-list-topIcon">
                  <img src="/assets/icons/aa1.png" alt="..." />
                </div>
                <div className="ship-list-bottomIcon">
                  <img src="/assets/icons/aa2.jpeg" alt="..." />
                </div>
                <h1>{translate("Our featured ships list")}</h1>
                <p>{translate("Write detail here")}</p>
              </div>
              <div className="ship-list-button">
                {/* <Button variant="contained">
                  View All
                  <BsArrowRightShort
                    style={{ fontSize: "15px", marginLeft: "10px" }}
                  />
                </Button> */}
              </div>
            </div>

            <div className="row class1 mb-5">
              {boat?.boats?.data?.length == 0 ? (
                <Stack
                  width="100%"
                  alignItems="center"
                  justifyContent="center"
                  backgroundColor="#fff"
                  border="1px solid #7c7777"
                >
                  <h2>{translate(boat?.empty_message)}</h2>
                </Stack>
              ) : (
                boat?.boats?.data?.map((item, index) => {
                  return (
                    <div className="col-lg-4 mt-4">
                      <Card className="card-container">
                        <CardMedia>
                          <div
                            id={`carouselExampleIndicators_${index}`}
                            className="carousel slide"
                            data-bs-ride="carousel"
                          >
                            <div className="carousel-indicators">
                              {item?.thumbnail?.map((image, i) => (
                                <button
                                  type="button"
                                  data-bs-target={`#carouselExampleIndicators_${index}`}
                                  data-bs-slide-to={i}
                                  className={i === 0 ? "active" : ""}
                                  aria-current={i === 0 ? "true" : "false"}
                                  key={i}
                                ></button>
                              ))}
                            </div>
                            <div className="carousel-inner">
                              {item?.thumbnail?.map((image, i) => (
                                <div
                                  className={`carousel-item ${
                                    i === 0 ? "active" : ""
                                  }`}
                                  key={i}
                                >
                                  <img
                                    src={image}
                                    style={{ width: "100%", height: "100%" }}
                                    // className="d-block w-100"
                                    alt={`Slide ${i + 1}`}
                                  />
                                </div>
                              ))}
                            </div>
                            <div className="boat-card-offer">
                              {item.discount > 0 && (
                                <h4>
                                  {showAmount(item?.discount)}{" "}
                                  {translate("% off")}
                                </h4>
                              )}
                              <Wishlist id={item.id} type={"boat"} />
                            </div>
                          </div>
                        </CardMedia>
                        <CardContent className="boat-card-content">
                          <div className="shipList-ranking">
                            <Typography className="shiplist-card-location">
                              <p>
                                <FaMapMarkerAlt
                                  style={{
                                    color: "#8BC53E",
                                    marginRight: "7px",
                                    fontSize: "15px",
                                  }}
                                />
                                <span>{item.location}</span>
                              </p>
                            </Typography>
                            <Typography className="shiplist-card-location">
                              <p>
                                <BsFillStarFill
                                  style={{
                                    color: "#8BC53E",
                                    marginRight: "7px",
                                    fontSize: "15px",
                                  }}
                                />
                                <span>
                                  {showAmount(item.rating)} ({item.review})
                                  {translate("Reviews")}
                                </span>
                              </p>
                            </Typography>
                          </div>

                          <div>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              className="shiplist-card-heading"
                            >
                              <h1>{item.name}</h1>
                              <h6 className="fw-bold">
                                {item?.boat_type?.name}
                              </h6>
                              <div
                                style={{
                                  fontSize: "15px",
                                }}
                              >
                                {item?.amenities?.map((a, index) => (
                                  <>
                                    <BiCheck
                                      style={{
                                        color: "#8bc53e",
                                      }}
                                    />
                                    <span> {a?.name} </span>
                                  </>
                                ))}
                              </div>
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              className="shiplist-card-discription"
                            ></Typography>
                          </div>

                          <Typography className="shiplist-card-deatil-button">
                            <h3>
                              {boat?.curr_sym}
                              {showAmount(item?.price)}{" "}
                              <span>/{translate(item.label)}</span>
                            </h3>
                            <button onClick={() => handleDetails(item)}>
                              {translate("View Detail")}
                            </button>
                          </Typography>
                        </CardContent>
                      </Card>
                    </div>
                  );
                })
              )}
            </div>
          </div>

          {/* <div className="boatPage-fastBoatCards">
            <FastBoatCards />
          </div> */}

          <div className="boatPage-footer">
            <Footer />
          </div>
        </>
      )}
    </Box>
  );
}

export default BoatPage;
