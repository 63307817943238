import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import classes from "./UserSideNavbar.module.css";
import { MdClose, MdKeyboardArrowRight, MdMenu } from "react-icons/md";
import { clearUser } from "../UserProfile/userSlice";
import { useDispatch } from "react-redux";
import { useLanguage } from "../../utils/LanguageContext";

function UserSideNavbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { langData, fetchLangData, translate } = useLanguage();

  const [selectedOption, setSelectedOption] = useState("dashboard");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to manage sidebar visibility

  const handleLogout = () => {
    dispatch(clearUser());
    localStorage.removeItem("bearerToken");
    localStorage.removeItem("userType");
    localStorage.removeItem("userEmail");
    navigate("/");
  };

  const handleMenuClick = (option) => {
    console.log("Selected Option:", option);
    setSelectedOption(option, () => {
      console.log("Selected Option 2:", selectedOption);
    });
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar visibility
  };

  return (
    <>
      <button className={classes["hamburger-button"]} onClick={toggleSidebar}>
        {isSidebarOpen ? <MdClose size={24} /> : <MdMenu size={24} />}
      </button>

      <nav
        className={` ${classes["user-navbar"]} ${
          isSidebarOpen ? classes["open"] : ""
        }`}
      >
        <div className={`${classes["user-navbar-container"]} container`}>
          <input type="checkbox" name="" id="" />
          <ul className={classes["user-menu-items"]}>
            {/* <h1
              className="userDashboard-subTitle mb-5"
              style={{ fontSize: "16px" }}
            >
              {translate('Personal Info')}
            </h1> */}
            <li>
              <Link
                className={
                  selectedOption === "dashboard"
                    ? `${classes["userside-nav-icon"]} ${classes[" userDashboard-selected-tab"]}`
                    : classes["userside-nav-icon"]
                }
                onClick={() => handleMenuClick("dashboard")}
                to="/dashboard"
              >
                <p>{translate("Availability Request")}</p>
                <MdKeyboardArrowRight
                  style={{
                    color: "#8BC53E",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                />
              </Link>
            </li>
            <li>
              <Link
                className={
                  selectedOption === "wishlist"
                    ? `${classes["userside-nav-icon"]} ${classes[" userDashboard-selected-tab"]}`
                    : classes["userside-nav-icon"]
                }
                onClick={() => handleMenuClick("wishlist")}
                to="/wishlist"
              >
                <p>{translate("Wishlist")}</p>
                <MdKeyboardArrowRight
                  style={{
                    color: "#8BC53E",
                    fontSize: "20px",
                  }}
                />
              </Link>
            </li>
            <li>
              <Link
                className={
                  selectedOption === "property-history"
                    ? `${classes["userside-nav-icon"]} ${classes[" userDashboard-selected-tab"]}`
                    : classes["userside-nav-icon"]
                }
                onClick={() => handleMenuClick("property-history")}
                to="/property-history"
              >
                <p>{translate("Property Booking History")}</p>
                <MdKeyboardArrowRight
                  style={{
                    color: "#8BC53E",
                    fontSize: "20px",
                  }}
                />
              </Link>
            </li>
            <li>
              <Link
                className={
                  selectedOption === "History"
                    ? `${classes["userside-nav-icon"]} ${classes[" userDashboard-selected-tab"]}`
                    : classes["userside-nav-icon"]
                }
                onClick={() => handleMenuClick("History")}
                to="/boat-history"
              >
                <p>{translate("Boat Booking History")}</p>
                <MdKeyboardArrowRight
                  style={{
                    color: "#8BC53E",
                    fontSize: "20px",
                  }}
                />
              </Link>
            </li>
            {/* <li>
              <Link
                className={
                  selectedOption === "paymentsPayout"
                    ? "userside-nav-icon userDashboard-selected-tab"
                    : "userside-nav-icon"
                }
                onClick={() => handleMenuClick("paymentsPayout")}
              >
                <p>Payments & payouts</p>
                <MdKeyboardArrowRight
                  style={{
                    color: "#8BC53E",
                    fontSize: "20px",
                  }}
                />
              </Link>
            </li> */}
            {/* <li>
              <Link
                className={
                  selectedOption == "notifications"
                    ? "userside-nav-icon userDashboard-selected-tab"
                    : "userside-nav-icon"
                }
                onClick={() => handleMenuClick("notifications")}
              >
                <p>Notifications</p>
                <MdKeyboardArrowRight
                  style={{
                    color: "#8BC53E",
                    fontSize: "20px",
                  }}
                />
              </Link>
            </li> */}
            <li>
              <Link
                className={
                  selectedOption === "userReviews"
                    ? `${classes["userside-nav-icon"]} ${classes[" userDashboard-selected-tab"]}`
                    : classes["userside-nav-icon"]
                }
                onClick={() => handleMenuClick("userReviews")}
                to="/user-reviews"
              >
                <p>{translate("Reviews")}</p>
                <MdKeyboardArrowRight
                  style={{
                    color: "#8BC53E",
                    fontSize: "20px",
                  }}
                />
              </Link>
            </li>
            <li>
              <Link
                className={
                  selectedOption === "supportTickets"
                    ? `${classes["userside-nav-icon"]} ${classes[" userDashboard-selected-tab"]}`
                    : classes["userside-nav-icon"]
                }
                onClick={() => handleMenuClick("supportTickets")}
                to="/support-ticket"
              >
                <p>{translate("Support Tickets")}</p>
                <MdKeyboardArrowRight
                  style={{
                    color: "#8BC53E",
                    fontSize: "20px",
                  }}
                />
              </Link>
            </li>
            <li>
              <Link
                className={
                  selectedOption === "changePassword"
                    ? `${classes["userside-nav-icon"]} ${classes[" userDashboard-selected-tab"]}`
                    : classes["userside-nav-icon"]
                }
                onClick={() => handleMenuClick("changePassword")}
                to="/change-password"
              >
                <p>{translate("Change Password")}</p>
                <MdKeyboardArrowRight
                  style={{
                    color: "#8BC53E",
                    fontSize: "20px",
                  }}
                />
              </Link>
            </li>
            <li>
              <Link
                className={
                  selectedOption === "profile"
                    ? `${classes["userside-nav-icon"]} ${classes[" userDashboard-selected-tab"]}`
                    : classes["userside-nav-icon"]
                }
                onClick={() => handleMenuClick("profile")}
                to="/profile"
              >
                <p>{translate("Profile")}</p>
                <MdKeyboardArrowRight
                  style={{
                    color: "#8BC53E",
                    fontSize: "20px",
                  }}
                />
              </Link>
            </li>
            {/* <li>
              <Link
                className={
                  selectedOption === "loginSecurity"
                    ? "userside-nav-icon userDashboard-selected-tab"
                    : "userside-nav-icon"
                }
                onClick={() => handleMenuClick("loginSecurity")}
                to="/login-security"
              >
                <p>Login & security</p>
                <MdKeyboardArrowRight
                  style={{
                    color: "#8BC53E",
                    fontSize: "20px",
                  }}
                />
              </Link>
            </li> */}
            <li>
              <Link className={classes["userside-nav-icon"]}>
                <p onClick={handleLogout}>{translate("Logout")}</p>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default UserSideNavbar;
