import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AvatarImg from "../../Components/Avatar/AvatarImg";
import { Elements } from "@stripe/react-stripe-js";
import { CircularProgress, Stack } from "@mui/material";
import { showAmount } from "../../utils/showAmount";
import { addLeadingZero } from "../../utils/addLeadingZero";
import { loadStripe } from "@stripe/stripe-js";
import { useLanguage } from "../../utils/LanguageContext";
import makeHttpRequest from "../../utils/api";
import { BiWallet } from "react-icons/bi";
import { BsBuildings, BsDoorOpen } from "react-icons/bs";
import { IoBedOutline, IoBoatSharp } from "react-icons/io5";
import { MdOutlineDoorFront } from "react-icons/md";

function DashboardHome() {
  const [loading, setLoading] = useState(false);
  const [curr_sym, setCurrSym] = useState("");
  const { langData, fetchLangData, translate } = useLanguage();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
  const [balance, setBalance] = useState("");
  const [total_properties, setTotalProperties] = useState("");
  const [total_rooms, setTotalRooms] = useState("");
  const [total_boats, setTotalBoats] = useState("");
  const [total_room_category, setTotalRoomCategory] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await makeHttpRequest("GET", "owner/dashboard");
      setCurrSym(response?.data?.curr_sym);
      setBalance(response?.data?.balance);
      setTotalProperties(response?.data?.total_properties);
      setTotalRooms(response?.data?.total_rooms);
      setTotalRoomCategory(response?.data?.total_room_category);
      setTotalBoats(response?.data?.total_boats);
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="dashboard-content">
      <Elements stripe={stripePromise}>
        {loading && (
          <Stack height="100vh" alignItems="center" justifyContent="center">
            <CircularProgress size={100} sx={{ color: "#8bc53e" }} />
          </Stack>
        )}

        {!loading && (
          <div className="supportTicket-bg">
            <div className="table-responsive" style={{ borderRadius: "10px" }}>
              <div className="supportTicket-wrapper">
                <div className="row mx-1">
                  <div className="col-lg-3 col-md-6 col-sm-12 p-1">
                    <div className="card balance-card">
                      <div className="card-header header-box">
                        {translate("Available Balance")}
                      </div>
                      <div className="card-body d-flex justify-content-between align-items-center">
                        <span>
                          <BiWallet className="dashboard-icons" />
                        </span>
                        <h2>
                          {curr_sym} {showAmount(balance)}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 p-1">
                    <div className="card total-properties">
                      <div className="card-header header-box">
                        {translate("Total Properties")}
                      </div>
                      <div className="card-body d-flex justify-content-between align-items-center">
                        <span>
                          <BsBuildings className="dashboard-icons" />
                        </span>
                        <h2>{addLeadingZero(total_properties)}</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 p-1">
                    <div className="card total-rooms">
                      <div className="card-header header-box">
                        {translate("Total Rooms")}
                      </div>
                      <div className="card-body d-flex justify-content-between align-items-center">
                        <span>
                          <BsDoorOpen className="dashboard-icons" />
                        </span>
                        <h2>{addLeadingZero(total_rooms)}</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 p-1">
                    <div className="card room-category">
                      <div className="card-header header-box">
                        {translate("Total Room Categories")}
                      </div>
                      <div className="card-body d-flex justify-content-between align-items-center">
                        <span>
                          <IoBedOutline className="dashboard-icons" />
                        </span>
                        <h2>{addLeadingZero(total_room_category)}</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 p-1">
                    <div className="card total-baots">
                      <div className="card-header header-box">
                        {translate("Total Boats")}
                      </div>
                      <div className="card-body d-flex justify-content-between align-items-center">
                        <span>
                          <IoBoatSharp className="dashboard-icons" />
                        </span>
                        <h2>{addLeadingZero(total_boats)}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Elements>
    </div>
  );
}

export default DashboardHome;
