import React, { useEffect, useState } from "react";
import makeHttpRequest from "../../utils/api";
import { Link, useLocation, useParams } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { CircularProgress, Stack } from "@mui/material";

function CustomCarousel() {
  const location = useLocation();
  const { id, slug } = useParams();

  const [accommodation, setAccommodation] = useState({});
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchAccommodationData = async (endpoint) => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", endpoint);
      const data = res.data;
      setAccommodation(data);
      setPropertyTypes(data.property_types || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.state) {
      const res = location.state;
      setAccommodation(res.data);
      setPropertyTypes(res.data.property_types || []);
    } else if (id && slug) {
      fetchAccommodationData(` search-location/${id}/${slug}`);
    } else {
      fetchAccommodationData("property");
    }
  }, [id, slug, location.state]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const CustomRightArrow = ({ onClick }) => (
    <button onClick={onClick} className="boat-button right-arrow">
      <FaChevronRight />
    </button>
  );

  const CustomLeftArrow = ({ onClick }) => (
    <button onClick={onClick} className="boat-button left-arrow">
      <FaChevronLeft />
    </button>
  );

  return (
    <>
      <h1 className="carousel-title fw-bold">
        {accommodation.page_title || "Accommodations"}
      </h1>
      <div className="carousel-wrapper">
        {loading ? (
          <Stack alignItems="center" justifyContent="center">
            <CircularProgress size={100} sx={{ color: "#8bc53e" }} />
          </Stack>
        ) : (
          <div>
            {propertyTypes.length > 0 ? (
              <Carousel
                swipeable={false}
                draggable={false}
                showDots={false}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={5000}
                responsive={responsive}
                customRightArrow={<CustomRightArrow />}
                customLeftArrow={<CustomLeftArrow />}
              >
                {propertyTypes.map((item) => (
                  <Link
                    className="link-wrapper"
                    key={item.id}
                    to={`/accomodation/search-location/${item.id}/${item.slug} `}
                  >
                    <div className="property-type">
                      <div className="property-type-content">
                        <img
                          src={item.image}
                          alt={item.name}
                          className="property-type-image"
                        />
                        <h2 className="property-type-heading text-black">
                          {item.name}
                        </h2>
                      </div>
                    </div>
                  </Link>
                ))}
              </Carousel>
            ) : (
              <p className="text-center fw-bold">
                No property types available.
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default CustomCarousel;
