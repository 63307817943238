import React, { useEffect, useState } from "react";
import "./ContactUsPage.css";
import { TiSocialFacebook } from "react-icons/ti";
import { RiInstagramFill } from "react-icons/ri";
import { MdOutlineMailOutline } from "react-icons/md";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import Toaster from "../../Components/Toaster";
import makeHttpRequest from "../../utils/api";
import { CircularProgress } from "@mui/material";
import { useLanguage } from "../../utils/LanguageContext";
import { MdNavigateNext } from "react-icons/md";
import { Link } from "react-router-dom";

function ContactUsPage() {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    name: "",
    lastName: "",
    message: "",
    email: "",
    phone: "",
  });
  const [site_contact, setSiteContact] = useState("");
  const { translate } = useLanguage();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await makeHttpRequest("GET", "contact");
      setSiteContact(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      if (!state.name) {
        Toaster("info", "Enter your name");
        return;
      }
      if (!state.email) {
        Toaster("info", "Enter your  email");
        return;
      }
      if (!state.phone) {
        Toaster("info", "Enter your phone number ");
        return;
      }
      if (!state.message) {
        Toaster("info", "Enter message");
        return;
      }

      setLoading(true);

      const data = {
        name: state.name + " " + state.lastName,
        email: state.email,
        phone: state.phone,
        message: state.message,
      };

      console.log("Payload being sent to API:", data); // Log the payload

      const res = await makeHttpRequest("POST", "contact", data);

      setLoading(false);
      if (res.success === true) {
        Toaster("success", res.message);
        setState({
          name: "",
          lastName: "",
          message: "",
          email: "",
          phone: "",
        });
      } else {
        Toaster("error", "Error");
      }
    } catch (error) {
      console.error("Error is :", error);
      setLoading(false);
    }
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    if (!loading) {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  return (
    <>
      <Navbar />
      <div className="container">
        <div className="contactUs-heading">
          <h1>{translate("Contact Us")}</h1>
          <div className="d-flex flex-row">
            <div ClassName="p-1">
              <Link to="/">
                <p className="text-black"> {translate("Home")}</p>
              </Link>
            </div>
            <div ClassName="p-1">
              <MdNavigateNext />
            </div>
            <div ClassName="p-1">
              <p>{translate("Contact us")}</p>
            </div>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="contact-us-card">
              <img src="/assets/icons/officeLocation.png" alt="..." />
              <h3>{translate("Office Location")}</h3>
              <p>{translate(site_contact?.contact?.contact_address)}</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="contact-us-card">
              <img src="/assets/icons/contact-us.jpg" alt="..." />
              <h3>{translate("Hotline")}</h3>
              <p>{translate(site_contact?.contact?.contact_number)}</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="contact-us-card">
              <img src="/assets/icons/officeEmail.png" alt="..." />
              <h3>{translate("Email Address")}</h3>
              <p>{translate(site_contact?.contact?.email_address)}</p>
            </div>
          </div>
        </div>

        <div className="row mt-5 pt-5">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="address-map">
              <h1>{translate(site_contact?.contact?.title)}</h1>
              <p>
                {translate(
                  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem cum, aperiam beatae, maiores molestiae a sequi  ducimus quae saepe quam veritatis laborum."
                )}
              </p>
            </div>
            <div className="gmap_canvas">
              <iframe
                title=".."
                id="gmap_canvas"
                src={site_contact?.contact?.google_map_embed_url}
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
              ></iframe>
            </div>

            <div className="d-flex flex-row mt-5">
              <div className="p-2 icon-container">
                <Link
                  to="https://www.facebook.com/caprieasyapp"
                  target="_blank"
                >
                  <span>
                    <TiSocialFacebook />
                  </span>
                </Link>
              </div>
              <div className="p-2 icon-container">
                <Link to="https://www.instagram.com/caprieasy" target="_blank">
                  <span>
                    <RiInstagramFill />
                  </span>
                </Link>
              </div>
              <div className="p-2 icon-container">
                <Link to="mailto:info@caprieasy.com" target="_blank">
                  <span>
                    <MdOutlineMailOutline />
                  </span>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="contact-us-form">
              <h3>{translate("Personal details")}</h3>
              <div className="row">
                <div className="col-lg-6 col-mf-12 col-sm-12">
                  <input
                    type="text"
                    placeholder={translate("First Name")}
                    name="name"
                    value={state.name}
                    onChange={handleInput}
                  />
                </div>
                <div className="col-lg-6 col-mf-12 col-sm-12">
                  <input
                    type="text"
                    placeholder={translate("Last Name")}
                    name="lastName"
                    value={state.lastName}
                    onChange={handleInput}
                  />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <input
                    type="email"
                    placeholder={translate("Email")}
                    name="email"
                    value={state.email}
                    onChange={handleInput}
                  />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <input
                    type="phone"
                    placeholder={translate("Phone Number")}
                    name="phone"
                    value={state.phone}
                    onChange={handleInput}
                  />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <label>{translate("Your Text")}</label>
                  <div>
                    <textarea
                      placeholder={translate("write a message")}
                      name="message"
                      value={state.message}
                      onChange={handleInput}
                    />
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 col-12  mt-4 form-btn">
                  {loading && <CircularProgress size={40} />}
                  {!loading && (
                    <button onClick={handleSubmit}>
                      {translate("Submit")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="contactUs-fastBoatCards">
        <FastBoatCards />
      </div> */}
      </div>

      <div className="contactUs-footer">
        <Footer />
      </div>
    </>
  );
}

export default ContactUsPage;
