import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { useLanguage } from "../../utils/LanguageContext";
import { BiMenu } from "react-icons/bi";
import AvatarImg from "../../Components/Avatar/AvatarImg";
import AllPropertyInquiry from "../AllPropertyInquiry/AllPropertyInquiry";
import AllBoatInquiry from "../AllBoatInquiry/AllBoatInquiry";
import AllProperty from "../AllProperty/AllProperty";
import RoomCategory from "../RoomCategory/RoomCategory";
import AllBookedProperty from "../AllBookedProperty/AllBookedProperty";
import PendingBookedProperty from "../PendingBookedProperty/PendingBookedProperty";
import SuccessBookedProperty from "../SuccessBookedProperty/SuccessBookedProperty";
import CancelBookedProperty from "../CancelBookedProperty/CancelBookedProperty";
import AllBoat from "../AllBoat/AllBoat";
import AllBookedBoat from "../AllBookedBoat/AllBookedBoat";
import PendingBookedBoat from "../PendingBookedBoat/PendingBookedBoat";
import SuccessBookedBoat from "../SuccessBookedBoat/SuccessBookedBoat";
import CancelBookedBoat from "../CancelBookedBoat/CancelBookedBoat";
import WithdrawMoney from "../WithdrawMoney/WithdrawMoney";
import WithdrawLog from "../WithdrawLog/WithdrawLog";
import OpenTicket from "../OpenTicket/OpenTicket";
import MyTicket from "../MyTicket/MyTicket";
import DashboardHome from "../DashboardHome/DashboardHome";
import OwnerSideBar from "../OwnerSideBar/OwnerSideBar";
import { useLocation } from "react-router-dom";
import CustomInquires from "../CustomBoatInquires/CustomInquires";
import Chat from "../../Pages/chatbot/owner/OwnerChat";
// import Chat from "../../Pages/chatbot/Chat";

const Dashboard = () => {
  const location = useLocation();
  console.log("location", location.state);

  // Set defaultTab to "dashboard-home" if location.state is not defined
  let defaultTab = "dashboard-home";

  if (location.state) {
    defaultTab = location.state;
  }

  const [activeTab, setActiveTab] = useState(defaultTab);
  console.log("activeTab", activeTab);

  if (location.state === "all-Boat") {
    defaultTab = "all-Boat";
  } else if (location.state === "all-property") {
    defaultTab = "all-property";
  } else if (location.state === "all-booked-boat") {
    defaultTab = "all-booked-boat";
  } else if (location.state === "roomCategory") {
    defaultTab = "roomCategory";
  } else if (location.state === "pending-booking-property") {
    defaultTab = "pending-booking-property";
  } else {
    defaultTab = "dashboard-home";
  }

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  // Close sidebar when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebar = document.querySelector(".sidebar");
      if (sidebar && isSidebarOpen && !sidebar.contains(event.target)) {
        handleCloseSidebar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarOpen]);

  useEffect(() => {
    console.log("Current active tab:", activeTab);
  }, [activeTab]);

  return (
    <>
      <div className="row">
        <div className="col-lg-2 col-md-2">
          <OwnerSideBar
            isSidebarOpen={isSidebarOpen}
            handleCloseSidebar={handleCloseSidebar}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
        {isSidebarOpen && (
          <div className="sidebar-backdrop" onClick={handleToggleSidebar}></div>
        )}

        <div className="col-lg-10">
          <div className="d-flex justify-content-between align-items-center p-2 ml-2">
            <div className="d-none d-lg-block"></div>
            <BiMenu
              className=" done-block hamburger-icon d-lg-none"
              onClick={handleToggleSidebar}
            />

            <div>
              <AvatarImg />
            </div>
          </div>

          <div className="container">
            {activeTab === "dashboard-home" && <DashboardHome />}
            {activeTab === "Chats" && <Chat />}
            {activeTab === "all-property-inquirery" && <AllPropertyInquiry />}
            {activeTab === "AllBoatInquiry" && <AllBoatInquiry />}
            {activeTab === "Inqury-method" && <CustomInquires />}
            {activeTab === "all-property" && <AllProperty />}
            {/* {activeTab === "all-booked-property" && (
              <div>
                <AllBookedProperty />
              </div>
            )} */}
            {activeTab === "pending-booking-property" && (
              <PendingBookedProperty />
            )}
            {activeTab === "success-booked-property" && (
              <SuccessBookedProperty />
            )}
            {activeTab === "cancle-booked-property" && <CancelBookedProperty />}
            {activeTab === "all-Boat" && <AllBoat />}
            {/* {activeTab === "all-booked-boat" && (
              <div>
                <AllBookedBoat />
              </div>
            )} */}
            {activeTab === "pending-booked-Boat" && <PendingBookedBoat />}
            {activeTab === "success-Booked-Boat" && <SuccessBookedBoat />}
            {activeTab === "cancle-booked-boat" && <CancelBookedBoat />}
            {activeTab === "Withdraw-money" && <WithdrawMoney />}
            {activeTab === "withdraw-log" && <WithdrawLog />}
            {activeTab === "open-ticket" && <OpenTicket />}
            {activeTab === "ticket-history" && <MyTicket />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
