import React from "react";
import "./AccomodationIndividuals.css";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BsStarFill, BsFillHeartFill } from "react-icons/bs";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link, useParams } from "react-router-dom";
import { BsFillStarFill } from "react-icons/bs";
import Footer from "../../Components/Footer/Footer";
import FastBoatCards from "../../Components/FastBoatCards/FastBoatCards";
import { BsArrowRightShort, BsShare } from "react-icons/bs";
import { IoChevronForwardSharp } from "react-icons/io5";
import { useRef, useState } from "react";
import { useEffect } from "react";
import makeHttpRequest from "../../utils/api";
import { CircularProgress, Stack } from "@mui/material";
import Navbar from "../../Components/Navbar/Navbar";
import { showAmount } from "../../utils/showAmount";
import { BiCheck } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { MdClose } from "react-icons/md";
import Toaster from "../../Components/Toaster";
import { useDispatch, useSelector } from "react-redux";
import { userLogged } from "../../UserDashboardComponent/UserProfile/userSlice";
import he from "he";
import SocialShare from "../../Components/SocialShare";
import Wishlist from "../../Components/Wishlist";
import { useLanguage } from "../../utils/LanguageContext";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import DetailCarousal from "../../Components/LocationCarousal/DetailCarousal";
import { title } from "../../utils/helpers";

const style = {
  // position: "absolute",
  // top: "40%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
  borderRadius: "16px",
  border: "none",
  outline: "none",
};

function AccomodationDetails() {
  const navigate = useNavigate();
  const { id, slug } = useParams();
  const [details, setDetails] = useState("");
  const [selectedRoom, setSelectedRoom] = useState(null);

  let firstImg = details?.property?.images ? details?.property?.images[0] : "";
  let secondImg = details?.property?.images ? details?.property?.images[1] : "";
  let thirdImg = details?.property?.images ? details?.property?.images[2] : "";
  let fourthImg = details?.property?.images ? details?.property?.images[3] : "";
  let fifthImg = details?.property?.images ? details?.property?.images[4] : "";

  const [images, setImages] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [show, setShow] = useState(false);

  const fetchImages = async () => {
    try {
      const response = await makeHttpRequest("POST", "images", {
        id,
        type: "accomodation",
      });
      console.log("API Response:", response.data);
      setImages(response?.data?.images);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const handleShow = () => {
    setShow(true);
    fetchImages();
  };

  const handleRoomClick = (room) => {
    if (selectedRoom?.id === room.id) {
      setSelectedRoom(null); // Deselect the room if clicked again
    } else {
      setSelectedRoom(room); // Set the selected room
    }
  };

  const handleCarouselChange = (index) => {
    setActiveIndex(index);
  };

  function generateSlug(inputString) {
    const slug = inputString.toLowerCase().replace(/[^a-z0-9]+/g, "-");
    return slug.replace(/^-+|-+$/g, "");
  }

  const handleSimilarDetails = (item) => {
    const resultSlug = generateSlug(item.name);

    navigate(`/boat/${item?.id}/${resultSlug}/details`);
  };

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const [state, setState] = useState({
    username: "",
    password: "",
  });
  const { langData, fetchLangData, translate } = useLanguage();
  const { isLoggedIn } = useSelector((state) => state.user);
  // Calendar Variable
  const [checkInDate, setCheckInDate] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [days, setDays] = useState(0);
  const [platform_fee, setPlatformFee] = useState(0);

  const handleCheckInChange = (event) => {
    setCheckInDate(event.target.value);
    calculateDateDifference(event.target.value, checkOutDate);
  };

  const handleCheckOutChange = (event) => {
    setCheckOutDate(event.target.value);
    calculateDateDifference(checkInDate, event.target.value);
  };

  const calculateDateDifference = (checkIn, checkOut) => {
    if (checkIn && checkOut) {
      const checkInDateObj = new Date(checkIn);
      const checkOutDateObj = new Date(checkOut);

      if (checkOutDateObj <= checkInDateObj) {
        alert("Check-out date must be later than check-in date");
        setDays(0);
        setCheckOutDate(checkIn + 1);
      } else {
        const differenceInMilliseconds = checkOutDateObj - checkInDateObj;
        const differenceInDays =
          differenceInMilliseconds / (1000 * 60 * 60 * 24);
        setDays(differenceInDays);
      }
    } else {
      setDays(0);
    }
  };

  const handleOpen = async (event) => {
    setOpen(true);
  };
  // const handleClose = () => setOpen(false);
  const handleClose = () => {
    setShow(false);
    setOpen(false);
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", `property/${id}/${slug}`);
      setDetails(res.data);
      document.title = title(res?.data?.property?.name);
      setLoading(false);
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  const handleDetails = (p_id, p_slug, room_id) => {
    navigate(`/accomodation/${p_id}/${p_slug}/rooms/${room_id}`);
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogin = async () => {
    try {
      if (!state.username) {
        Toaster("info", "Enter username");
        return;
      }
      if (!state.password) {
        Toaster("info", "Enter password");
        return;
      }
      setLoading(true);
      const data = {
        username: state.username,
        password: state.password,
        user_type: "user",
      };
      const res = await makeHttpRequest("POST", "login", data);

      setLoading(false);
      if (res.success === true) {
        localStorage.setItem("bearerToken", res.data.token);
        localStorage.setItem("userType", res.data.type);
        localStorage.setItem("userEmail", res.data.user.email);

        Toaster("success", res.message);
        dispatch(userLogged("user"));
        window.reload();
      } else {
        Toaster("error", "Invalid credentials");
      }
    } catch (error) {
      console.error("Error is :", error);
      setLoading(false);
    }
  };

  const handleEnter = (name) => {
    if (name == "username") {
      if (!state.username) {
        Toaster("info", "Enter email");
      } else if (state.username && !state.password) {
        passwordRef.current.focus();
      }
      if (state.username && state.password) {
        handleLogin();
      }
    } else {
      if (!state.password) {
        Toaster("info", "Enter password");
      } else if (!state.username && state.password) {
        usernameRef.current.focus();
      }
      if (state.username && state.password) {
        handleLogin();
      }
    }
  };

  const handleSoleInquiry = async () => {
    if (details?.property?.is_complete == 1) {
      if (checkInDate && checkOutDate) {
        try {
          setLoading(true);
          const res = await makeHttpRequest("POST", `user/property/booking`, {
            property: id,
            start: checkInDate,
            end: checkOutDate,
            days: days,
          });
          Toaster("success", res.message);
          setLoading(false);
        } catch (error) {
          console.error("Error is:", error);
          setLoading(false);
        }
      } else {
        Toaster("error", "Select Check in and Check out date");
      }
    }
  };

  return (
    <>
      <Navbar />
      {loading && (
        <Stack height="80vh" alignItems="center" justifyContent="center">
          <CircularProgress size={100} sx={{ color: "#8bc53e" }} />
        </Stack>
      )}
      {!loading && details && details?.property?.length === 0 && (
        <Stack my={5} alignItems="center" justifyContent="center">
          <h2>{translate(details?.empty_message)}</h2>
        </Stack>
      )}
      {!loading && (
        <div className="container">
          <Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="modal-wrapper">
              <Box sx={style}>
                <div className="modal-heading">
                  <h1>{translate("Login")}</h1>
                  <MdClose
                    onClick={handleClose}
                    style={{
                      color: "#FFF",
                      cursor: "pointer",
                      fontSize: "38px",
                      paddingRight: "10px",
                    }}
                  />
                </div>
                <div className="modal-inputs">
                  <div className="scrollable-content">
                    <div className="form-group">
                      <label>{translate("User name")}</label>
                      <input
                        type="username"
                        name="username"
                        className="form-control-sm"
                        placeholder={`${translate("Enter Username")}`}
                        ref={usernameRef}
                        value={state.username}
                        onChange={handleInput}
                        onKeyDown={(e) => {
                          if (e.key == "Enter") {
                            handleEnter("username");
                          }
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>{translate("Password")}</label>
                      <input
                        type="password"
                        name="password"
                        className="form-control-sm"
                        placeholder={`${translate("Enter your password")}`}
                        ref={passwordRef}
                        value={state.password}
                        onChange={handleInput}
                        onKeyDown={(e) => {
                          if (e.key == "Enter") {
                            handleEnter("password");
                          }
                        }}
                      />
                    </div>
                    <div className="mt-1">
                      <button className="btn btn-primary" onClick={handleLogin}>
                        {translate("Login")}
                      </button>
                    </div>
                  </div>
                </div>
              </Box>
            </div>
          </Modal>

          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="mt-5">
                <DetailCarousal images={details?.property?.images} />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 ">
              {details?.property?.room_categories?.length > 0 && (
                <div className="border rounded-4 mt-5 p-3">
                  <h1 className="accomodation-feature-heading mt-2">
                    {translate("Room Categories")}
                  </h1>
                  <div className="room-category-list">
                    <ul className="list-group">
                      {details.property.room_categories.map((room, i) => (
                        <li
                          key={i}
                          className={`list-group-item ${
                            selectedRoom?.id === room.id ? "active" : ""
                          } room-list-item`}
                          onClick={() => handleRoomClick(room)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="d-flex justify-content-between">
                            <div>{room.name}</div>
                            <div className="accomodation-beds">
                              <img src="/assets/icons/Bed.png" alt="Bed icon" />
                              <img
                                src="/assets/icons/Bedkids.png"
                                alt="Bed kids icon"
                              />
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* Show the selected room details if one is selected */}
                  {selectedRoom && (
                    <div className="room-details-box mt-3 p-3 border rounded">
                      <h3>{selectedRoom.name}</h3>
                      <ul className="list-unstyled">
                        {selectedRoom?.amenities?.map((am, i) => (
                          <li key={i} className="d-flex align-items-center">
                            <BiCheck className="mr-2" />
                            {am.name}
                          </li>
                        ))}
                      </ul>
                      <button
                        className="btn btn-primary w-100"
                        onClick={() =>
                          handleDetails(
                            details?.property?.id,
                            details?.property?.slug,
                            selectedRoom?.id
                          )
                        }
                      >
                        {translate("Room Details")}
                      </button>
                    </div>
                  )}

                  {details?.property?.is_complete === 1 ? (
                    <div className="col-lg-4">
                      <div className="accommodation-right-bg">
                        <div>
                          <div className="accommodation-ranking-wrapper mt-3">
                            <div className="accommodation-ranking">
                              {details?.property?.price > 0 && (
                                <h3>
                                  {details?.curr_sym}
                                  {showAmount(details?.property?.price)}{" "}
                                  <span>{details?.property?.label}</span>
                                </h3>
                              )}
                            </div>
                            <div className="accommodation-reviewsRating">
                              <p>
                                <BsFillStarFill
                                  style={{
                                    color: "#8BC53E",
                                    marginRight: "7px",
                                    fontSize: "15px",
                                  }}
                                />
                                <span>
                                  <a href="#">
                                    {showAmount(details?.property?.rating)} (
                                    {details?.property?.review}) reviews
                                  </a>
                                </span>
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-12 mt-3">
                              <div className="accomodation-date-wrapper">
                                {/* <img src="/assets/icons/Calendar.png" alt="....." /> */}
                                <div className="accomodation-content">
                                  <input
                                    type="date"
                                    placeholder="Check In"
                                    value={checkInDate}
                                    onChange={handleCheckInChange}
                                    min={new Date().toISOString().split("T")[0]}
                                  />
                                  {/* <h2>Check-out</h2> */}

                                  <p>{details?.property?.date_from}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12 mt-3 ">
                              <div className="accomodation-date-wrapper">
                                {/* <img src="/assets/icons/Calendar.png" alt="....." /> */}
                                <div className="accomodation-content">
                                  <input
                                    type="date"
                                    placeholder="Check Out"
                                    value={checkOutDate}
                                    onChange={handleCheckOutChange}
                                    min={(function () {
                                      const tomorrow = new Date();
                                      tomorrow.setDate(tomorrow.getDate() + 1);
                                      return tomorrow
                                        .toISOString()
                                        .split("T")[0];
                                    })()}
                                  />

                                  {/* <h2>Check-out</h2> */}
                                  <p>{details?.property?.date_to}</p>
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-12 mt-3 ">
                      <div className="accomodation-date-wrapper">
                        <img src="/assets/icons/guestIcon.png" alt="....." />
                        <div className="accomodation-content">
                          <h2>Guest</h2>
                          <p>02 Guest</p>
                        </div>
                      </div>
                    </div> */}
                          </div>

                          <div className="accomodationRent-perNight mt-3 p-0">
                            <p>
                              {details?.curr_sym}
                              {showAmount(details?.property?.price)} x {days}{" "}
                              {details?.property?.label}
                            </p>
                            <p>
                              {details?.curr_sym}
                              {parseInt(showAmount(details?.property?.price)) *
                                parseInt(days)}
                            </p>
                          </div>
                          <div className="accomodationRent-perNight mt-2 p-0 ">
                            <p>{translate("Discount")}</p>
                            <p>{showAmount(details?.property?.discount, 0)}%</p>
                          </div>
                          {/* <div className="accomodationRent-perNight mt-2 p-0 ">
                      <p>{translate("Capri Easy Service")}</p>
                      <p>
                        {details?.curr_sym}
                        {platform_fee}
                      </p>
                    </div> */}
                          <hr />
                          <div className="accomodationRent-perNight-total mt-2 p-0 ">
                            <p>{translate("Total")}</p>
                            <p>
                              {details?.curr_sym}
                              {showAmount(details?.property?.discount, 0) > 0
                                ? parseInt(
                                    showAmount(
                                      details?.property?.price -
                                        (details?.property?.price *
                                          showAmount(
                                            details?.property?.discount,
                                            0
                                          )) /
                                          100
                                    )
                                  ) *
                                    parseInt(days) +
                                  parseInt(platform_fee)
                                : parseInt(
                                    showAmount(details?.property?.price)
                                  ) *
                                    parseInt(days) +
                                  parseInt(platform_fee)}
                            </p>
                          </div>

                          <div className="accomdation-reserver">
                            {/* <Link to="/accomodationPage/EntryStep1"> */}
                            {localStorage.getItem("bearerToken") && (
                              <button onClick={handleSoleInquiry}>
                                {translate("Send Inquiry")}
                              </button>
                            )}
                            {!localStorage.getItem("bearerToken") && (
                              <button onClick={handleOpen}>
                                {translate("Send Inquiry")}
                              </button>
                            )}
                            {/* </Link> */}
                            <p>{translate("You won’t be charged yet")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="accommodation-right-bg">
                      <div>
                        <div className="accommodation-ranking-wrapper">
                          <div className="accommodation-ranking">
                            {details?.property?.price > 0 && (
                              <h3>
                                {details?.curr_sym}
                                {showAmount(details?.property?.price)}{" "}
                                <span>{details?.property?.label}</span>
                              </h3>
                            )}
                          </div>
                          <div className="accommodation-reviewsRating mt-4">
                            <p>
                              <BsFillStarFill
                                style={{
                                  color: "#8BC53E",
                                  marginRight: "7px",
                                  fontSize: "15px",
                                }}
                              />
                              <span>
                                <a href="#">
                                  {showAmount(details?.property?.rating)} (
                                  {details?.property?.review}) reviews
                                </a>
                              </span>
                            </p>
                          </div>
                        </div>

                        <div className="accomodationRent-star mt-3 p-0">
                          <h3 className="fw-bold">
                            {details?.property?.star} Star{" "}
                            {details?.property?.property_type?.name}
                          </h3>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* {details?.property?.is_complete == 1 ? (
                    <div className="col-lg-4">
                      <div className="accommodation-right-bg">
                        <div>
                          <div className="accommodation-ranking-wrapper">
                            <div className="accommodation-ranking">
                              {details?.property?.price > 0 && (
                                <h3>
                                  {details?.curr_sym}
                                  {showAmount(details?.property?.price)}{" "}
                                  <span>{details?.property?.label}</span>
                                </h3>
                              )}
                            </div>
                            <div className="accommodation-reviewsRating">
                              <p>
                                <BsFillStarFill
                                  style={{
                                    color: "#8BC53E",
                                    marginRight: "7px",
                                    fontSize: "15px",
                                  }}
                                />
                                <span>
                                  <a href="#">
                                    {showAmount(details?.property?.rating)} (
                                    {details?.property?.review}) reviews
                                  </a>
                                </span>
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-12 mt-3">
                              <div className="accomodation-date-wrapper">
                                <div className="accomodation-content">
                                  <input
                                    type="date"
                                    placeholder="Check In"
                                    value={checkInDate}
                                    onChange={handleCheckInChange}
                                    min={new Date().toISOString().split("T")[0]}
                                  />
                                  <p>{details?.property?.date_from}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12 mt-3 ">
                              <div className="accomodation-date-wrapper">
                                <div className="accomodation-content">
                                  <input
                                    type="date"
                                    placeholder="Check Out"
                                    value={checkOutDate}
                                    onChange={handleCheckOutChange}
                                    min={(function () {
                                      const tomorrow = new Date();
                                      tomorrow.setDate(tomorrow.getDate() + 1);
                                      return tomorrow
                                        .toISOString()
                                        .split("T")[0];
                                    })()}
                                  />
                                  <p>{details?.property?.date_to}</p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="accomodationRent-perNight mt-3 p-0">
                            <p>
                              {details?.curr_sym}
                              {showAmount(details?.property?.price)} x {days}{" "}
                              {details?.property?.label}
                            </p>
                            <p>
                              {details?.curr_sym}
                              {parseInt(showAmount(details?.property?.price)) *
                                parseInt(days)}
                            </p>
                          </div>
                          <div className="accomodationRent-perNight mt-2 p-0 ">
                            <p>{translate("Discount")}</p>
                            <p>{showAmount(details?.property?.discount, 0)}%</p>
                          </div>
                          <hr />
                          <div className="accomodationRent-perNight-total mt-2 p-0 ">
                            <p>{translate("Total")}</p>
                            <p>
                              {details?.curr_sym}
                              {showAmount(details?.property?.discount, 0) > 0
                                ? parseInt(
                                    showAmount(
                                      details?.property?.price -
                                        (details?.property?.price *
                                          showAmount(
                                            details?.property?.discount,
                                            0
                                          )) /
                                          100
                                    )
                                  ) *
                                    parseInt(days) +
                                  parseInt(platform_fee)
                                : parseInt(
                                    showAmount(details?.property?.price)
                                  ) *
                                    parseInt(days) +
                                  parseInt(platform_fee)}
                            </p>
                          </div>

                          <div className="accomdation-reserver">
                            {localStorage.getItem("bearerToken") && (
                              <button onClick={handleSoleInquiry}>
                                {translate("Send Inquiry")}
                              </button>
                            )}
                            {!localStorage.getItem("bearerToken") && (
                              <button onClick={handleOpen}>
                                {translate("Send Inquiry")}
                              </button>
                            )}

                            <p>{translate("You won’t be charged yet")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="accommodation-right-bg">
                      <div>
                        <div className="accommodation-ranking-wrapper mt-3">
                          <div className="accommodation-ranking">
                            {details?.property?.price > 0 && (
                              <h3>
                                {details?.curr_sym}
                                {showAmount(details?.property?.price)}{" "}
                                <span>{details?.property?.label}</span>
                              </h3>
                            )}
                          </div>
                          <div className="accommodation-reviewsRating">
                            <p>
                              <BsFillStarFill
                                style={{
                                  color: "#8BC53E",
                                  marginRight: "7px",
                                  fontSize: "15px",
                                }}
                              />
                              <span>
                                <a href="#">
                                  {showAmount(details?.property?.rating)} (
                                  {details?.property?.review}) reviews
                                </a>
                              </span>
                            </p>
                          </div>
                        </div>

                        <div className="accomodationRent-star mt-3 p-0">
                          <h2>
                            {details?.property?.star} Star{" "}
                            {details?.property?.property_type?.name}
                          </h2>
                        </div>
                      </div>
                    </div>
                  )} */}
                </div>
              )}
            </div>
          </div>
          <Modal
            className="accomodation-image-carousal-modal"
            open={show}
            style={{ marginTop: "120px" }}
          >
            <div className="carousel accomodation-image-carousal">
              <div className="carousel-inner">
                {images.length > 0 &&
                  images.map((imageUrl, index) => (
                    <div
                      key={index}
                      className={`carousel-item ${
                        index === activeIndex ? "active" : ""
                      }`}
                      style={{
                        backgroundImage: `url(${imageUrl})`,
                        backgroundSize: "contain",
                        backgroundPosition: " center",
                        backgroundRepeat: "no-repeat",
                        width: "100%",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <MdClose
                          onClick={handleClose}
                          style={{
                            color: "#FFF",
                            fontSize: "35px",
                            cursor: "pointer",
                            marginTop: "20px",
                            marginRight: "20px",
                            background: "#45aaf0",
                            borderRadius: "5px",
                            padding: "8px",
                          }}
                        />
                      </div>
                      <div></div>
                    </div>
                  ))}
              </div>
              <button
                className="carousel-control-prev-accomodation"
                onClick={() =>
                  handleCarouselChange(
                    (activeIndex - 1 + images.length) % images.length
                  )
                }
              >
                <IoIosArrowBack />
              </button>
              <button
                className="carousel-control-next-accomodation"
                onClick={() =>
                  handleCarouselChange((activeIndex + 1) % images.length)
                }
              >
                <IoIosArrowForward />
              </button>
              <div className="mt-lg-3 mt-md-3 d-none d-lg-block d-md-block">
                <div style={{ display: "flex", padding: "0px 20px" }}>
                  <div
                    className="w-100"
                    style={{
                      display: "flex",
                      justifyContent: "center  ",
                    }}
                  >
                    {images.length > 0 &&
                      images.map((t, index) => (
                        <div className="list-inline-item active" key={index}>
                          <a
                            id={`carousel-selector-${index}`}
                            data-slide-to={`${index}`}
                            data-target="#custCarousel"
                            onClick={() => handleCarouselChange(index)}
                          >
                            <div
                              style={{
                                backgroundImage: `url(${t})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center center",
                                backgroundRepeat: "no-repeat",
                                width: "150px",
                                height: "100px",
                                borderRadius: "10px",
                                cursor: "pointer",
                              }}
                            ></div>
                          </a>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <div className="row mt-2 mobile-img">
            <div className="col-lg-6 col-md-6 col-12 acommondationIndividual-img">
              <div className="row boat-wrapper class1 boat-detail-carouser-div">
                <Card className="p-0">
                  <CardMedia onClick={handleShow}>
                    <div
                      id={`carouselExampleIndicators`}
                      className="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <div className="carousel-indicators">
                        {details?.property?.image?.map((image, i) => (
                          <button
                            type="button"
                            data-bs-target={`#carouselExampleIndicators`}
                            data-bs-slide-to={i}
                            className={i === 0 ? "active" : ""}
                            aria-current={i === 0 ? "true" : "false"}
                            key={i}
                          ></button>
                        ))}
                      </div>
                      <div className="carousel-inner">
                        {details?.property?.image?.map((image, i) => (
                          <div
                            className={`carousel-item ${
                              i === 0 ? "active" : ""
                            } boat-detail-carouser-div`}
                            key={i}
                          >
                            <img
                              src={image}
                              className="d-block w-100"
                              alt={`Slide ${i + 1}`}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </CardMedia>
                </Card>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-8">
              <div className=" acommodation-left-bg">
                <div className="accomodation-location-wrapper">
                  <h3 className="accomodation-location">
                    {details?.property?.name}
                  </h3>
                  <div className="accomodation-wishlist-wrapper">
                    <Wishlist
                      id={details?.property?.id}
                      type={"property"}
                      style={{
                        padding: "3px 6px 0px 6px",
                        fontSize: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    />
                    {/* <p>Wishlist</p> */}
                  </div>
                </div>
                <div className="accomodation-location-icon">
                  <p>
                    <FaMapMarkerAlt
                      style={{
                        color: "#8BC53E",
                        marginRight: "7px",
                        fontSize: "15px",
                      }}
                    />
                    <span>{details?.property?.location}</span>
                  </p>
                </div>

                {details?.property?.amenities?.length > 0 && (
                  <h1 className="accomodation-feature-heading mt-5 mb-3">
                    {translate("Amenities")}
                  </h1>
                )}

                <div className="row">
                  {details?.property?.amenities?.map((amn, i) => {
                    return (
                      <div
                        key={i}
                        className="col-lg-4 col-md-6 col-12 mt-2 acommodation-feature-wrapper"
                      >
                        <i className="las la-paw"></i>
                        <div className="accomodation--feature">
                          <p>
                            <BiCheck style={{ fontSize: "20px" }} />
                            {amn?.name}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className=" acommodation-left-bg mt-5">
                <h1 className="accomodation-feature-heading mb-3">
                  {translate("Description")}
                </h1>
                <div
                  className="accomodation-discription"
                  dangerouslySetInnerHTML={{
                    __html: details?.property?.description
                      ? he.decode(details.property.description)
                      : "",
                  }}
                ></div>
              </div>
              <div className="row accomodation-LocationImg mt-5">
                <h1 className="accomodation-feature-heading mb-3">
                  {translate("Location")}
                </h1>
                <div className="col-lg-12 col-md-12 col-12">
                  <div className="mapouter w-100">
                    <div className="gmap_canvas">
                      {details?.property?.map_url && (
                        <iframe
                          id="gmap_canvas"
                          src={`${details?.property?.map_url}=UTF8&iwloc=&output=embed`}
                          frameBorder="0"
                          scrolling="no"
                          marginHeight="0"
                          marginWidth="0"
                        ></iframe>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className=" acommodation-left-bg mt-5">
                <h1 className="accomodation-feature-heading mb-3">
                  {translate("Host Information")}
                </h1>
                <div className="col-12 pl-0 mt-4 ">
                  <div className="Accomodationhost-name-wrapper">
                    <img
                      src="/assets/icons/avatar.png"
                      alt="....."
                      className="border rounded-circle"
                    />
                    <div className="Accomodationhost-name">
                      <h2>
                        {details?.property?.owner?.firstname}{" "}
                        {details?.property?.owner?.lastname}
                      </h2>
                      <p>
                        <BsStarFill style={{ color: "yellow" }} />
                        <span>
                          {showAmount(details?.property?.owner_rating)}(
                          {details?.property?.owner_review}{" "}
                          {translate("Review")})
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="Accomodationhost-discription">
                    <p>{details?.property?.owner?.bio}</p>
                  </div>
                  <div className="col-lg-12 pl-0 mt-4 accomodationHost-feature-wrapper">
                    <img src="/assets/icons/Calendar.png" alt="....." />
                    <div className="accomodation--feature">
                      <p>
                        {translate("Join in")}{" "}
                        {new Date(
                          details?.property?.owner?.created_at
                        ).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-12 pl-0 mt-4 accomodationHost-feature-wrapper">
                    <img src="/assets/icons/RankingRate.png" alt="....." />
                    <div className="accomodation--feature">
                      <p>{translate("Response Rate 100%")}</p>
                    </div>
                  </div>
                  <div className="col-lg-12 pl-0 mt-4 accomodationHost-feature-wrapper">
                    <img src="/assets/icons/Bookmark.png" alt="....." />
                    <div className="boatHost-feature">
                      <p>{translate("Fast response - within a few hours")}</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* REVIEWS */}
              <div className="mt-5">
                <h1 className="accomodation-reviewHeading">
                  {translate("Reviews")}
                  <span style={{ fontSize: "25px", fontWeight: "700" }}>
                    ({details?.property?.reviews?.length})
                  </span>
                </h1>
                <div className="row p-1">
                  {details?.property?.reviews?.length > 0 &&
                    details?.property?.reviews?.map((re, index) => (
                      <div
                        key={index}
                        className="col-lg-12 col-md-6 d-none d-lg-block d-md-block my-1 border"
                      >
                        <div className="reviews-wrapper my-3">
                          <div className="Accomodationhost-name-wrapper">
                            <img
                              src="/assets/icons/avatar.png"
                              alt="....."
                              className="border rounded-circle"
                            />
                            <div className="ml-4">
                              <div className="accomodation-reviewStars mb-2">
                                {Array.from(
                                  { length: re?.rating },
                                  (_, starIndex) => (
                                    <BsStarFill
                                      key={starIndex}
                                      style={{ color: "yellow" }}
                                    />
                                  )
                                )}
                              </div>
                              <div className="client-review">
                                <h2>
                                  {re?.user?.firstname} {re?.user?.lastname}
                                </h2>
                                <p>
                                  {re?.created_at &&
                                    new Date(re?.created_at).toLocaleDateString(
                                      "en-US",
                                      {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                      }
                                    )}
                                </p>
                              </div>

                              <p className="review-discription">
                                {re?.description}
                              </p>
                            </div>
                          </div>
                          <div className="reply-reviews" onClick={handleOpen}>
                            <h2>{translate("Login to reply")}</h2>
                          </div>
                        </div>
                      </div>
                    ))}
                  <div className="col-lg-12 col-md-6 d-none d-lg-block d-md-block mt-3">
                    <div className=" addReviview-wrapper">
                      <div className="add-review">
                        <h1>{translate("Add a review")}</h1>
                        {!isLoggedIn && (
                          <p>
                            {translate(
                              "You must be logged in to post a comment."
                            )}
                          </p>
                        )}
                      </div>
                      {isLoggedIn && (
                        <div className="older-comment-btn">
                          <Link to="/user-reviews">
                            <Button variant="contained">
                              {translate("My Reviews")}
                              <BsArrowRightShort
                                style={{ fontSize: "18px", marginLeft: "10px" }}
                              />
                            </Button>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {details?.boats?.length > 0 && (
            <div className="similar-accomdation-heading">
              <h1>{translate("Similar Boats")}</h1>
            </div>
          )}
          <div className="row" style={{ marginBottom: "100px" }}>
            {/* <div className="row class1 mb-5"> */}
            {details?.boats?.length > 0 &&
              details?.boats?.map((d, index) => {
                return (
                  <div className="col-lg-4 mt-4">
                    <Card>
                      <CardMedia>
                        <div
                          id={`carouselExampleIndicators_${index}`}
                          className="carousel slide"
                          data-bs-ride="carousel"
                        >
                          <div className="carousel-indicators">
                            {d.image.map((image, i) => (
                              <button
                                type="button"
                                data-bs-target={`#carouselExampleIndicators_${index}`}
                                data-bs-slide-to={i}
                                className={i === 0 ? "active" : ""}
                                aria-current={i === 0 ? "true" : "false"}
                                key={i}
                              ></button>
                            ))}
                          </div>
                          <div className="carousel-inner">
                            {d.image.map((image, i) => (
                              <div
                                className={`carousel-item ${
                                  i === 0 ? "active" : ""
                                }`}
                                key={i}
                              >
                                <img
                                  src={
                                    image != ""
                                      ? image
                                      : "/assets/icons/shipList1.png"
                                  }
                                  className="d-block w-100"
                                  alt={`Slide ${i + 1}`}
                                />
                              </div>
                            ))}
                          </div>
                          <div className="boat-card-offer">
                            <h4>
                              {d.discount}
                              {translate("%")}
                            </h4>
                            <div
                              style={{
                                position: "absolute",
                                top: "10px",
                                right: "20px",
                                color: "#FFF",
                                background: "#8BC53E",
                                padding: "4px 7px",
                                borderRadius: "50px",
                              }}
                            >
                              <BsFillHeartFill />
                            </div>
                          </div>
                        </div>
                      </CardMedia>
                      <CardContent className="">
                        <div className="shipList-ranking">
                          <Typography className="shiplist-card-location">
                            <p>
                              <FaMapMarkerAlt
                                style={{
                                  color: "#8BC53E",
                                  marginRight: "7px",
                                  fontSize: "15px",
                                }}
                              />
                              <span>{d.location}</span>
                            </p>
                          </Typography>
                          <Typography className="shiplist-card-location">
                            <p>
                              <BsFillStarFill
                                style={{
                                  color: "#8BC53E",
                                  marginRight: "7px",
                                  fontSize: "15px",
                                }}
                              />
                              <span>{d.review}</span>
                            </p>
                          </Typography>
                        </div>

                        <div>
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            className="shiplist-card-heading"
                          >
                            <h1>{d.name}</h1>
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            className="shiplist-card-discription"
                          >
                            <p>{d.boat_type}</p>
                            {/* <p>{d.guests} Guests</p>
                          <p>{d.bed} Bed</p>
                          <p>{d.cabin} Cabin</p>
                          <p>{d.bathroom} bathroom</p> */}
                          </Typography>
                        </div>

                        <Typography className="shiplist-card-deatil-button">
                          <h3>
                            {details?.curr_sym}
                            {d.price} <span>{d.label}</span>
                          </h3>
                          <button onClick={() => handleSimilarDetails(d)}>
                            {translate("View Detail")}
                          </button>
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                );
              })}
            {/* </div> */}
          </div>
        </div>
      )}
      {/* {!loading && (
        <div className="boatDetailPage-fastBoatCards">
          <FastBoatCards />
        </div>
      )} */}
      {!loading && (
        <div className="boatDetailPage-footer">
          <Footer />
        </div>
      )}
    </>
  );
}

export default AccomodationDetails;
