import React, { useEffect, useState } from "react";
import makeHttpRequest from "../../utils/api";
import Toaster from "../../Components/Toaster";
import "./AllPropertyInquiry.css";
import { Link } from "react-router-dom";
import { showAmount } from "../../utils/showAmount";
import { AiOutlineCheck } from "react-icons/ai";
import { useLanguage } from "../../utils/LanguageContext";
import Loader from "../../Components/Loader";
import { addLeadingZero } from "../../utils/addLeadingZero";
import DashboardSideNav from "../DashboardSideNav/DashboardSideNav";

function AllPropertyInquiry({ setShowMenu, showMenu }) {
  const [data, setData] = useState("");
  console.log("data", data);
  const [loading, setLoading] = useState(false);
  const { langData, fetchLangData, translate } = useLanguage();

  function getStatusBadge(status) {
    if (status === 0) {
      return (
        <span className="badge badge-warning">{translate("Pending")}</span>
      );
    } else if (status === 1) {
      return (
        <span className="badge badge-success">{translate("Success")}</span>
      );
    } else if (status === 2) {
      return <span className="badge badge-danger">{translate("Cancel")}</span>;
    } else if (status === 3) {
      return (
        <span className="badge badge-success">{translate("Approved")}</span>
      );
    } else if (status === 4) {
      return (
        <span className="badge badge-success">{translate("Rejected")}</span>
      );
    }
    return null;
  }

  async function handleStatusChange(id, status) {
    try {
      setLoading(true);
      const res = await makeHttpRequest(
        "GET",
        `owner/inquiry/property/status/${id}/${status}  `
      );
      setLoading(false);
      if (res.success) {
        Toaster("success", res?.message);

        // Update the local state to reflect the new status
        setData((prevData) => {
          const updatedProperties = prevData.booked_properties.data.map(
            (property) => {
              if (property.id === id) {
                return { ...property, status }; // Update the status
              }
              return property; // Return the unchanged property
            }
          );

          return {
            ...prevData,
            booked_properties: {
              ...prevData.booked_properties,
              data: updatedProperties, // Set the updated properties
            },
          };
        });
      } else {
        Toaster("error", "Something went wrong");
      }
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "owner/inquiry/property");
      setData(res.data);
      setLoading(false);
      if (res.success) {
        setData(res.data);
      } else {
        Toaster("error", "Error");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="mainContainer">
        <div className="allPropertyInquiry-heading">
          <h1>{translate(data?.type + " Property Inquiry")}</h1>
          <p>
            {translate("Total Property Inquiry")}{" "}
            <span>{addLeadingZero(data?.booked_properties?.total)}</span>
          </p>
        </div>

        <div className="container table-responsive py-5">
          <table className="table table-bordered table-hover">
            <thead className="">
              <tr>
                <th>{translate("S.N.")}</th>
                <th scope="col">{translate("Name")}</th>
                <th scope="col">{translate("Property Type")}</th>
                <th scope="col">{translate("Location")}</th>
                <th scope="col">{translate("Price")}</th>
                <th scope="col">{translate("Discount")}</th>
                <th scope="col">{translate("Discounted Price")}</th>
                <th scope="col">{translate("Status")}</th>
                <th scope="col">{translate("Action")}</th>
              </tr>
            </thead>
            <tbody>
              {data?.booked_properties?.data?.map((row, index) => (
                <>
                  <tr key={index}>
                    <td>
                      <div className="sr-no-bg">
                        {addLeadingZero(index + 1)}
                      </div>
                    </td>
                    <td>{row?.property.name}</td>
                    <td>{row?.property?.property_type?.name}</td>
                    <td>{row?.property?.location?.name}</td>
                    <td>
                      {data?.curr_sym}
                      {showAmount(row?.property?.price)}
                    </td>
                    <td>{showAmount(row?.property?.discount)}%</td>
                    <td>
                      {data?.curr_sym}
                      {showAmount(row?.total_price)}
                    </td>
                    <td>{getStatusBadge(row?.status)}</td>
                    <td>
                      {row?.status === 0 && (
                        <>
                          <Link
                            className="btn btn-sm btn-primary m-1"
                            onClick={() => handleStatusChange(row?.id, 3)}
                          >
                            <AiOutlineCheck />
                          </Link>
                          <Link
                            className="btn btn-sm btn-danger m-1"
                            onClick={() => handleStatusChange(row?.id, 4)}
                          >
                            {translate("Cancel")}
                          </Link>
                        </>
                      )}
                      {row?.status === 4 && (
                        <Link
                          className="btn btn-sm btn-primary m-1"
                          onClick={() => handleStatusChange(row?.id, 3)}
                        >
                          <AiOutlineCheck />
                        </Link>
                      )}
                      {row?.status === 3 && (
                        <Link
                          className="btn btn-sm btn-danger m-1"
                          onClick={() => handleStatusChange(row?.id, 4)}
                        >
                          {translate("Cancel")}
                        </Link>
                      )}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>

        {/* <div className="table-responsive">
          <div className="table-wrapper">
            <table id="mytable" className="table table-bordered table-striped">
              <thead className="inquiryProperty-table-th">
                <tr>
                  <th>{translate("S.N.")}</th>
                  <th>{translate("Name")}</th>
                  <th>{translate("Property Type")}</th>
                  <th>{translate("Location")}</th>
                  <th>{translate("Price")}</th>
                  <th>{translate("Discount")}</th>
                  <th>{translate("Discounted Price")}</th>
                  <th>{translate("Status")}</th>
                  <th>{translate("Action")}</th>
                </tr>
              </thead>
              <tbody>
                {data?.booked_properties?.data?.map((row, index) => (
                  <tr key={index} className="inquiryProperty-td-wrapper">
                    <td>
                      <div className="sr-no-bg">
                        {addLeadingZero(index + 1)}
                      </div>
                    </td>
                    <td>{row?.property.name}</td>
                    <td>{row?.property?.property_type?.name}</td>
                    <td>{row?.property?.location?.name}</td>
                    <td>
                      {data?.curr_sym}
                      {showAmount(row?.property?.price)}
                    </td>
                    <td>{showAmount(row?.property?.discount)}%</td>
                    <td>
                      {data?.curr_sym}
                      {showAmount(row?.total_price)}
                    </td>
                    <td>{getStatusBadge(row?.status)}</td>
                    <td>
                      {row?.status === 0 && (
                        <>
                          <Link
                            className="btn btn-sm btn-primary m-1"
                            onClick={() => handleStatusChange(row?.id, 3)}
                          >
                            <AiOutlineCheck />
                          </Link>
                          <Link
                            className="btn btn-sm btn-danger m-1"
                            onClick={() => handleStatusChange(row?.id, 4)}
                          >
                            {translate("Cancel")}
                          </Link>
                        </>
                      )}
                      {row?.status === 4 && (
                        <Link
                          className="btn btn-sm btn-primary m-1"
                          onClick={() => handleStatusChange(row?.id, 3)}
                        >
                          <AiOutlineCheck />
                        </Link>
                      )}
                      {row?.status === 3 && (
                        <Link
                          className="btn btn-sm btn-danger m-1"
                          onClick={() => handleStatusChange(row?.id, 4)}
                        >
                          {translate("Cancel")}
                        </Link>
                      )}
                    </td>
                  </tr>
                ))}
                {data?.booked_properties?.data?.length === 0 && (
                  <tr className="text-center">
                    <td colSpan={9}>{translate(data?.empty_message)}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default AllPropertyInquiry;
