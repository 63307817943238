import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BiMenu } from "react-icons/bi";
import { useSelector } from "react-redux";
import UserSidebar from "./UserSidebar";
import "./Navbar.css";
import { setStateBasedOnDomain } from "../../utils/helpers";
import { useLanguage } from "../../utils/LanguageContext";
import makeHttpRequest from "../../utils/api";
import { Button, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

function Navbar() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { pathname } = useLocation();
  const { isLoggedIn } = useSelector((state) => state.user);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("lang") || setStateBasedOnDomain()
  );
  const [activeTab, setActiveTab] = useState(pathname); // New state for active tab
  const [languages, setLanguages] = useState([]);
  const { translate, fetchLangData } = useLanguage();
  const navigate = useNavigate(); // Initialize navigate
  const toggleNav = () => setIsNavOpen((prev) => !prev);
  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const subMenuOpen = Boolean(subMenuAnchorEl);

  const fetchLanguages = useCallback(async () => {
    try {
      const res = await makeHttpRequest("GET", "language");
      setLanguages(res?.data?.languages || []);
    } catch (error) {}
  }, []);

  useEffect(() => {
    fetchLanguages();
  }, [fetchLanguages]);

  useEffect(() => {
    setActiveTab(pathname); // Update active tab whenever pathname changes
  }, [pathname]);

  const navItems = [
    { path: "/", label: "Home" },
    { path: "/accomodation", label: "Accommodations" },
    { path: "/boat", label: "Boat" },
    { path: "/blog", label: "Blog" },
    { path: "/contact", label: "Contact" },
  ];

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setSelectedLanguage(newLanguage);
    fetchLangData(newLanguage);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSubMenuAnchorEl(null); // Close sub-menu if open
  };

  const handleSubMenuOpen = (event) => {
    setSubMenuAnchorEl(event.currentTarget);
  };

  const handleSubMenuClose = () => {
    setSubMenuAnchorEl(null);
  };
  const handleMessageClick = () => {
    navigate("/dashboard", { state: { selectedTab: 8 } }); // Redirect to dashboard with selected tab as 8 (Chats)
  };

  return (
    <header className="site-navbar">
      <div className="">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="site-logo">
            <Link to="/" className="font-weight-bold">
              <img src="/assets/icons/whiteLogo.png" alt="Logo" />
            </Link>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleNav}
            aria-controls="navbarNav"
            aria-expanded={isNavOpen ? "true" : "false"}
            aria-label="Toggle navigation"
          >
            <BiMenu style={{ color: "#FFF", fontSize: "30px" }} />
          </button>
          <div
            className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`}
            id="navbarNav"
          >
            <ul className="site-menu">
              {navItems.map(({ path, label }) => (
                <li
                  key={path}
                  className={activeTab === path ? "active" : ""}
                  onClick={() => setActiveTab(path)} // Set active tab on click
                >
                  <Link className="nav-link" to={path}>
                    {label}
                  </Link>
                </li>
              ))}
            </ul>
            <div className="navbar-right-wrapper">
              {!isLoggedIn ? (
                <>
                  <button
                    className="login-button mr-3"
                    variant="contained"
                    endIcon={<ArrowDropDownIcon />}
                    onMouseEnter={handleClick}
                    onClick={handleClick}
                  >
                    Login as
                  </button>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{ onMouseLeave: handleClose }}
                  >
                    <Link className="link-wrapper" to="/login">
                      <MenuItem className="ancher-wrapper">User Login</MenuItem>
                    </Link>
                    <Link className="link-wrapper" to="/owner-login">
                      <MenuItem className="ancher-wrapper">Host login</MenuItem>
                    </Link>
                  </Menu>
                </>
              ) : (
                <>
                  <button className="message-btn" onClick={handleMessageClick}>
                    Message
                  </button>
                  <Link to="/dashboard">
                    <button className="dashboard-btn">Dashboard</button>
                  </Link>
                  <UserSidebar />
                </>
              )}

              {/* <Dropdown>
                {!isLoggedIn ? (
                  <>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Login
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item>Action</Dropdown.Item>
                      <Dropdown.Item href="/login">User Login</Dropdown.Item>
                      <Dropdown.Item href="/owner-login">
                        Host Login
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </>
                ) : (
                  <>
                    <Link to="/chat">
                      <button className="message-btn">Message</button>
                    </Link>
                    <Link to="/dashboard">
                      <button>Dashboard</button>
                    </Link>
                    <UserSidebar />
                  </>
                )}
              </Dropdown> */}
              {/* {!isLoggedIn ? (
                <>
                  <Link to="/login">
                    <button>User Login</button>
                  </Link>
                  <Link to="/register">
                    <button>User Register</button>
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/chat">
                    <button className="message-btn">Message</button>
                  </Link>
                  <Link to="/dashboard">
                    <button>Dashboard</button>
                  </Link>
                  <UserSidebar />
                </>
              )} */}
            </div>
            <div className="language-dropdown">
              <select
                id="language-select"
                value={selectedLanguage}
                onChange={handleLanguageChange}
              >
                {languages.map((lan) => (
                  <option key={lan.code} value={lan.code}>
                    {translate(lan.name)}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Navbar;
