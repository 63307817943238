import React, { useEffect, useState } from "react";
import makeHttpRequest from "../../utils/api";
import Toaster from "../../Components/Toaster";
import { Link, useNavigate } from "react-router-dom";
import "./AllProperty.css";
import { useLanguage } from "../../utils/LanguageContext";
import Loader from "../../Components/Loader";
import { addLeadingZero } from "../../utils/addLeadingZero";
import AddProperty from "../AddNewProperty/AddNewProperty";
import { BsArrowLeft } from "react-icons/bs";
import { TiEdit } from "react-icons/ti";
import { MdDelete } from "react-icons/md";

function AllProperty() {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const { translate } = useLanguage();
  const [activeTab, setActiveTab] = useState("all-properties"); // Default active tab

  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "owner/properties");
      setData(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteThisItem = async (id) => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "owner/property/destroy/" + id);
      getData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleEditClick = (id) => {
    navigate(`/property/${id}/edit`);
  };
  const fetchProperties = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "owner/properties");
      setData(res.data);
    } catch (error) {
      console.error("Error fetching properties:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddPropertySuccess = () => {
    fetchProperties(); // Fetch properties again after adding a new one
    setActiveTab("all-properties"); // Optionally switch back to the all-properties tab
  };

  // Function to render different content based on the active tab
  const renderTabContent = () => {
    switch (activeTab) {
      case "all-properties":
        return (
          <div className="inner-container">
            <div className="table-responsive">
              <div className="table-wrapper">
                <table className="table table-bordered table-striped">
                  <thead className="allProperty-table-th">
                    <tr>
                      <th>{translate("S.N.")}</th>
                      <th>{translate("Name")}</th>
                      <th>{translate("Property Type")}</th>
                      <th>{translate("Location")}</th>
                      <th>{translate("Discount")}</th>
                      <th>{translate("Room")}</th>
                      <th>{translate("Categories")}</th>
                      <th>{translate("Status")}</th>
                      <th>{translate("Action")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.properties?.data?.map((row, index) => (
                      <tr key={index} className="allProperty-td-wrapper">
                        <td>{addLeadingZero(index + 1)}</td>
                        <td>{row.name}</td>
                        <td>{row.property_type_name}</td>
                        <td>{row.location}</td>
                        <td>{row.discount}</td>
                        <td>
                          {row.is_complete === 1
                            ? translate("Not Apply")
                            : row.room_count}
                        </td>
                        <td>
                          {row.is_complete === 1
                            ? translate("Not Apply")
                            : row.room_category_count}
                        </td>
                        <td>
                          {row.status === 1 ? (
                            <span className="badge badge-success">
                              {translate("Active")}
                            </span>
                          ) : (
                            <span className="badge badge-warning">
                              {translate("Inactive")}
                            </span>
                          )}
                        </td>
                        <td>
                          <div className="d-flex justify-content-center gap-3 align-items-center">
                            <div>
                              {" "}
                              <div
                                title="edit"
                                className="all-baot-edit-icon"
                                onClick={() => handleEditClick(row?.id)}
                              >
                                <span>
                                  <TiEdit />
                                </span>
                                {/* {translate(" Edit")} */}
                              </div>
                            </div>
                            <div
                              title="delete"
                              className="all-baot-delete-icon"
                              onClick={() => deleteThisItem(row?.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <span>
                                <MdDelete />
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                    {data?.properties?.data?.length === 0 && (
                      <tr className="text-center">
                        <td colSpan={9}>{translate(data?.empty_message)}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        );

      case "add-property":
        return (
          <div>
            <AddProperty
              setActiveTab={setActiveTab}
              onAddPropertySuccess={handleAddPropertySuccess}
            />
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className="mainContainer">
          <div className="allProperty-headingWrappers">
            <div className="allProperty-heading">
              {activeTab === "add-property" && (
                <div className="tab-container d-flex gap-3 mt-5 pt-4">
                  <div
                    className={`tab  text-white fw-bold    ${
                      activeTab === "all-properties" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("all-properties")}
                  ></div>
                </div>
              )}
              {activeTab === "all-properties" && (
                <div className="tab-container d-flex gap-3">
                  <div
                    className={`tab  text-white fw-bold  ${
                      activeTab === "add-property" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("add-property")}
                  >
                    <h1>{translate("Add Property")}</h1>
                    <p>
                      {translate("Total Property")}{" "}
                      <span>{addLeadingZero(data?.properties?.total)}</span>
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="">
              {activeTab === "add-property" && (
                <div className="tab-container d-flex gap-3">
                  <div
                    className={`tab addProperty-addBtn text-white fw-bold    ${
                      activeTab === "all-properties" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("all-properties")}
                  >
                    {translate("All Properties")}
                  </div>
                </div>
              )}
              {activeTab === "all-properties" && (
                <div className="tab-container d-flex gap-3">
                  <div
                    className={`tab addProperty-addBtn text-white fw-bold  ${
                      activeTab === "add-property" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("add-property")}
                  >
                    {translate("+ Add New Property")}
                  </div>
                </div>
              )}
            </div>
          </div>
          {renderTabContent()}
        </div>
      )}
    </>
  );
}

export default AllProperty;
